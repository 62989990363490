import * as React from "react"
import Layout from "../../components/layout"
import Laptops from "../../images/hctv/ipad_mock.jpg"
import Iphones from "../../images/i360/single_phone_mockup.jpg"
import { ArrowNarrowRightIcon } from "@heroicons/react/outline"
import OldSite from "../../images/i360/insight360-IT-Healthcare-Simplified.png"
import NewSite from "../../images/i360/Home-insight360.png"
import ReactCompareImage from 'react-compare-image'
import { borderRadius } from "polished"

const Insight = () => {
  return (
    <Layout>
      <div className="my-24 w-full md:w-4/5 mx-auto">
        <div className="flex items-baseline h-96 flex-col justify-center">
          <h1 className="text-5xl md:text-6xl font-black">
            <span className="highlight px-0.5">i360</span>
          </h1>
          <p className="text-lg mt-4">Re-branding and Web Development</p>
          <div className="flex h-8 mt-4">
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              web
            </p>
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              branding
            </p>
          </div>
        </div>
        <div className="my-10 flex flex-col gap-10">
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">ABOUT</h4>
            <div>
              <p className="text-lg">
                i360 is a managed service provider that primarily serves small
                businesses. This project was a complete re-brand to modernize
                their look and create a cohesive brand presence.
              </p>
              <div className="grid grid-cols-2 gap-4 mt-6">
                <p className="font-semibold">December 2021</p>
                <p>
                  GatsbyJS, React, Javascript, HTML, CSS, TailwindCSS
                </p>
                <p className="font-semibold">Website and Branding</p>
                <p>
                  <strong>Client:</strong> i360
                </p>
              </div>
            </div>
          </div>
          <a
            href="https://i360.netlify.app"
            target="_blank" rel="noreferrer"
            className="p-10 bg-gray-100 rounded-lg text-xl font-bold text-center flex justify-center items-center group"
          >
            Visit the Site <span className="w-6 ml-2"><ArrowNarrowRightIcon/></span>
          </a>
          <img className="col-span-2 rounded-lg" src={Iphones} alt="mockup" />
          <ReactCompareImage className="col-span-2 rounded-lg" leftImage={NewSite} rightImage={OldSite} />
        </div>
      </div>
    </Layout>
  )
}

export default Insight
